import {Injectable} from '@angular/core';
import {isNil} from 'lodash';
import {Lead} from 'src/app/domain/model/lead/lead.model';
import {isPlanSettingsAmountConfigured} from 'src/app/domain/model/lead/plan-settings.model';
import {Onboarding} from 'src/app/domain/model/onboarding.model';
import {isInvestmentPlan, ProductType} from 'src/app/domain/model/product/product.model';
import {AppEnvironmentService} from 'src/app/infrastructure/environment/app-environment/app-environment.service';
import {Router} from 'src/app/infrastructure/router/router';

@Injectable({
  providedIn: 'root'
})

export default class OnboardingRouteResolver {
  constructor(
    private appEnvironment: AppEnvironmentService,
    private router: Router,
  ) {
  }

  public resolvePortfolio() {
    if (this.appEnvironment.isAppForThirdPartners()) {
      return 'onboarding/editable-summary/scenarios';
    }
    return this.router.navigateToBack();
  }

  public resolveResetKyc() {
    return 'onboarding/show-results/';
  }

  public resolveEditAmountUrl(): string {
    return '/onboarding/editable-summary/amount';
  }

  public resolveSelectInvestmentStrategyUrl(): string {
    return '/onboarding/investment-strategy';
  }

  public resolveShowResults(router: Router, onboarding: Onboarding, lead: Lead): string {
     if (this.appEnvironment.isAppForThirdPartners()) {
       return '/onboarding/editable-summary/portfolio';
     }

    return this.resolve(router, onboarding, lead);
  }

  public resolveSelectProductUrl(): string {
    return '/onboarding/editable-summary/product';
  }

  public resolveRequestEmail(): string {
    return 'onboarding/customer/email-confirm';
  }

  public resolveKycQuestions(): string {
    return '/onboarding/show-results/';
  }

  public resolveChangePortfolio(): string {
    return '/onboarding/editable-summary/portfolio/change';
  }

  public redirectToProductAdvisor(): string {
    return '/onboarding/product-advisor';
  }

  public resolvePotentialAmounts(): string {
    return '/onboarding/potential-amounts';
  }

  public resolveKyc(): string {
    return '/onboarding/kyc';
  }

  public resolveMain(): string {
    return '/onboarding/main';
  }

  public resolveProductInfo(productType: ProductType): string {
    return '/onboarding/editable-summary/product/info/' + productType;
  }

  public resolveAllocation(){
    return '/onboarding/editable-summary/allocation';
  }

  public resolve(router: Router, onboarding: Onboarding, lead: Lead): string {
    if (!onboarding && this.appEnvironment.isLoginProviderExternal() && this.appEnvironment.loginProvider()) {
      return '/onboarding' + this.appEnvironment.loginProvider();
    }

    if (!onboarding) {
      return '/onboarding';
    }

    if (!onboarding.productSelectedAt && !AppEnvironmentService.isExternalPartner()) {
      return '/onboarding/potential-amounts';
    }

    if(router.currentUrl().url === '/onboarding/editable-summary/product') {
      return this.resolveSelectInvestmentStrategyUrl();
    }

    if (!isPlanSettingsAmountConfigured(lead.planSettings) &&
      !AppEnvironmentService.isExternalPartner() &&
      !isInvestmentPlan(lead.planSettings.product)) {
      return '/onboarding/amount';
    }

    if (!onboarding.profiledAt) {
      return this.resolveKyc();
    }

    if (
      (router.currentUrl().url === '/onboarding/editable-summary' && !this.appEnvironment.isAppForThirdPartners()) ||
      (this.appEnvironment.isEvoPartner() && router.currentUrl().url ==='/onboarding/editable-summary/portfolio/change')
    ) {
      return '/onboarding/editable-summary/scenarios';
    }

    if (this.appEnvironment.isAppForThirdPartners() && router.currentUrl().url === '/onboarding/editable-summary/portfolio/change'){
      return '/onboarding/editable-summary/portfolio';
    }

    if (router.currentUrl().url === '/onboarding/amount'
      || router.currentUrl().url.includes('/onboarding/show-results')
      || router.currentUrl().url.includes('/onboarding/investment-strategy')
      || router.currentUrl().url === '/onboarding/editable-summary/portfolio/change'
      || router.currentUrl().url.includes('/product/info')
    ) {
      return '/onboarding/editable-summary';
    }

    if (!onboarding.planConfirmedAt || this.resolveKyc() === router.currentUrl().url) {
      return '/onboarding/show-results';
    }

    if (!!onboarding.planConfirmedAt && router.currentUrl().url === '/onboarding/editable-summary/portfolio/change'){
      return '/onboarding/editable-summary';
    }

    if (this.appEnvironment.isAppForThirdPartners() && router.currentUrl().url === '/onboarding/editable-summary/scenarios/onboarding') {
      return '/onboarding/amount';
    }

    if (this.appEnvironment.isAppForThirdPartners() && router.currentUrl().url === '/onboarding/amount') {
      return '/onboarding/editable-summary';
    }

    if (!onboarding.emailConfirmedAt) {
      return '/onboarding/customer/email';
    }

    if (!onboarding.phoneConfirmedAt) {
      return '/onboarding/customer/phone';
    }

    if (!onboarding.dataFilledAt) {
      return '/onboarding/customer/data';
    }

    if (!onboarding.vatUploadedAt) {
      return '/onboarding/customer/vat';
    }

    if (lead.planSettings.isForChild) {
      const route = this.resolveChild(onboarding);
      if (route) {
        return route;
      }
    }

    if (!isInvestmentPlan(lead.planSettings.product) && isNil(onboarding.ibanFilledAt)) {
      return '/onboarding/iban';
    }

    if (!onboarding.documentsSignedAt) {
      return '/onboarding/docs';
    }

    if (onboarding.documentsSignedAt) {
      return '/onboarding/done';
    }

    throw new Error('No Onboarding route could be resolved at current status');
  }

  private resolveChild(onboarding: Onboarding): string | null {
    if (!onboarding.childDataFilledAt) {
      return '/onboarding/child/data';
    }

    if (!onboarding.childVatUploadedAt) {
      return '/onboarding/child/vat';
    }

    if (!onboarding.childRelationshipProofUploadedAt) {
      return '/onboarding/child/relationship';
    }

    return null;
  }
}
