import {BackofficePortfolio} from 'src/app/domain/model/backoffice-portfolio/backoffice-portfolio.model';

export const USA_PLUS_NAME = 'usa+';

export enum InvestmentStrategyNames {
  global = 'global',
  monetary =  'monetary',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'usa+' = 'usa+',
}

export enum InvestmentStrategyIcons {
  global = 'earth-outline',
  monetary =  'cellular-outline',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'usa+' = 'assets/icons/usa-flag.svg'
}

export enum InvestmentStrategyCategories {
  mediumLongTerm = 'Medium-Long Term',
  liquidityRemuneration = 'Liquidity remuneration',
}

export interface InvestmentStrategy {
  id: string;
  name: string;
  category: string;
  order: number;
}

export interface InvestmentStrategyGroup {
  investmentStrategy: InvestmentStrategy;
  minTolerance: number;
  boPortfolios: BackofficePortfolio[];
}

export interface InvestmentStrategyGroupsByCategory {
  category: string;
  strategyGroups: InvestmentStrategyGroup[];
}

export const isGlobalInvestmentStrategy = (investmentStrategy: InvestmentStrategy): boolean =>
  investmentStrategy.name.toLowerCase() === InvestmentStrategyNames.global;

export const isMonetaryInvestmentStrategy = (investmentStrategy: InvestmentStrategy): boolean =>
  investmentStrategy.name.toLowerCase() === InvestmentStrategyNames.monetary;

export const isValidStrategyName = (strategyName: string): boolean =>
  Object.values(InvestmentStrategyNames).includes(strategyName as InvestmentStrategyNames);

export const getStrategyIconByName = (strategyName: string): string =>
  InvestmentStrategyIcons[strategyName.toLowerCase()];

export const isRecommended = (investmentStrategy: InvestmentStrategy) => {
  const currentStrategyName = investmentStrategy.name.toLowerCase();
  return currentStrategyName === InvestmentStrategyNames.global || currentStrategyName === InvestmentStrategyNames.monetary;
};

export const getInvestmentStrategyCategoryTranslationKey = (category: string): string =>
  `investmentStrategy.categories.${category.toLowerCase()}.title`;

export const getInvestmentStrategyNameTitleTranslationKey = (name: string): string =>
  `investmentStrategy.info.${name.toLowerCase()}.title`;

export const getInvestmentStrategyNameTranslationKey = (name: string): string =>
  `investmentStrategy.info.${name.toLowerCase()}.name`;

export const getInvestmentStrategyGroupFullNameTranslationKey = (investmentStrategyGroup: InvestmentStrategyGroup): string =>
  `investmentStrategy.info.${investmentStrategyGroup.investmentStrategy.name.toLowerCase()}.fullName`;

export const getInvestmentStrategyChecks = (investmentStrategy: InvestmentStrategy): string[] => {
  switch (investmentStrategy.name.toLowerCase()) {
    case InvestmentStrategyNames.global:
      return [
        'investmentStrategy.select.info.global.first',
        'investmentStrategy.select.info.global.second',
        'investmentStrategy.select.info.global.third',
      ];
    case InvestmentStrategyNames.monetary:
      return [
        'investmentStrategy.select.info.monetary.first',
        'investmentStrategy.select.info.monetary.second',
        'investmentStrategy.select.info.monetary.third',
      ];
    case InvestmentStrategyNames[USA_PLUS_NAME]:
      return [
        'investmentStrategy.select.info.usa+.first',
        'investmentStrategy.select.info.usa+.second',
        'investmentStrategy.select.info.usa+.third',
      ];
    default:
      return [];
  }
};

export const groupBoPortfoliosByInvestmentStrategy = (boPortfolios: BackofficePortfolio[]): InvestmentStrategyGroup[] => {
  const investmentStrategyGroups: InvestmentStrategyGroup[] = [];

  boPortfolios.forEach((boPortfolio) => {
    const existingGroup = investmentStrategyGroups.find((group) => group.investmentStrategy.id === boPortfolio.investmentStrategy.id);
    if (existingGroup) {
      existingGroup.boPortfolios.push(boPortfolio);
      if (boPortfolio.tolerance < existingGroup.minTolerance) {
        existingGroup.minTolerance = boPortfolio.tolerance;
      }
    } else {
      investmentStrategyGroups.push({
        investmentStrategy: boPortfolio.investmentStrategy,
        minTolerance: boPortfolio.tolerance,
        boPortfolios: [boPortfolio]
      });
    }
  });

  return investmentStrategyGroups.sort((a, b) => a.investmentStrategy.order - b.investmentStrategy.order);
};

export const groupBoPortfoliosByStrategyCategories = (boPortfolios: BackofficePortfolio[]): InvestmentStrategyGroupsByCategory[] => {
  const investmentStrategyGroups = groupBoPortfoliosByInvestmentStrategy(boPortfolios);
  const investmentStrategyGroupsByCategory: InvestmentStrategyGroupsByCategory[] = [];

  investmentStrategyGroups.forEach((group) => {
    const existingCategoryGroup = investmentStrategyGroupsByCategory
      .find((categoryGroup) => categoryGroup.category === group.investmentStrategy.category);

    if (existingCategoryGroup) {
      existingCategoryGroup.strategyGroups.push(group);
    } else {
      investmentStrategyGroupsByCategory.push({
        category: group.investmentStrategy.category,
        strategyGroups: [group]
      });
    }
  });

  return investmentStrategyGroupsByCategory;
};
