import {Injectable} from '@angular/core';
import {toArray} from 'lodash';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {BackofficePortfolio} from 'src/app/domain/model/backoffice-portfolio/backoffice-portfolio.model';
import BackofficePortfolioStore from 'src/app/domain/store/backoffice-portfolio.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';
import {InvestmentStrategy} from 'src/app/domain/model/investment-strategy/investment-strategy.model';

@Injectable({
  providedIn: 'root',
})
export default class BackofficePortfolioApi {

  private readonly url = 'bo/portfolio';
  private fetching: boolean;

  constructor(private http: ApiClient, private store: BackofficePortfolioStore) {
    this.fetching = false;
  }

  public async fetchAll(): Promise<void> {
    this.fetching = true;
    const data: BackofficePortfolio[] = toArray(await this.http.get(this.url));
    this.store.saveAll(data);
    this.fetching = false;
  }

  public syncAllBackofficePortfolios(): BackofficePortfolio[] {
    return this.store.sync();
  }

  public boPortfolios(): Observable<BackofficePortfolio[]> {
    return this.store.portfolios$.pipe(
      tap(stored => {
        if (!stored.length && !this.fetching) {
          this.fetchAll();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public byId(portfolioId: string): Observable<BackofficePortfolio | null> {
    return this.boPortfolios().pipe(
      map(portfolios => portfolios.find((portfolio) => portfolio.id === portfolioId))
    );
  }
  public async waitById(portfolioId: string): Promise<BackofficePortfolio> {
    let backofficePortfolios;
    await this.waitFetchAllAndSync().
      then(portfolios => backofficePortfolios = portfolios);
    return backofficePortfolios.filter(portfolio => portfolio.id === portfolioId)[0];
  }

  public byProductToleranceAndStrategy(
    productId: string,
    tolerance: number,
    investmentStrategy: InvestmentStrategy
  ): Observable<BackofficePortfolio | null> {
    return this.boPortfolios().pipe(
      map(portfolios =>
        portfolios.find((portfolio) =>
            portfolio.tolerance === tolerance
            && portfolio.productId === productId
            && portfolio.investmentStrategy.id === investmentStrategy.id
        )
      )
    );
  }

  public byProduct(productId: string): Observable<BackofficePortfolio[]> {
    return this.boPortfolios().pipe(
      map(portfolios => portfolios.filter((portfolio) => (portfolio.productId === productId)))
    );
  }

  public async waitFetchAllAndSync() {
    let boPortfolio = this.syncAllBackofficePortfolios();
    if (!boPortfolio.length) {
      await this.fetchAll();
      boPortfolio = this.syncAllBackofficePortfolios();
    }
    return boPortfolio;
  }

  public maxTolerance(productId: string): Observable<number>
  {
    const portfolios = this.byProduct(productId);
    return portfolios.pipe(
      map(array => Math.max(...array.map(p => p.tolerance)))
    );
  }
}
