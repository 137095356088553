import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {defer} from 'lodash';
import {from, Observable, switchMap, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {toPromise} from 'src/app/domain/function/subscription.helper';
import {Auth, AuthGrantType} from 'src/app/domain/model/auth.model';
import AuthApi from 'src/app/domain/service/api/auth.api';
import RouteResolverService from 'src/app/domain/service/route-resolver/route-resolver.service';
import AuthHttpInterceptorStateStore, {AuthHttpInterceptorState} from 'src/app/domain/store/auth-http-interceptor-state.store';
import AuthStore from 'src/app/domain/store/auth.store';
import {UserSession} from 'src/app/infrastructure/environment/user-session';
import {isNotInternetConnection, isServerError, isSuccess, isUnauthorized} from 'src/app/infrastructure/http/http-response';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private me: Auth | null;
  private state: AuthHttpInterceptorState;

  constructor(
    private readonly userSession: UserSession,
    private readonly authStore: AuthStore,
    private readonly authApi: AuthApi,
    private readonly routeResolver: RouteResolverService,
    private readonly authHttpInterceptorStore: AuthHttpInterceptorStateStore,
    private readonly router: Router
  ) {
    this.authStore.me$.subscribe((auth) => this.me = auth);
    this.authHttpInterceptorStore.state$.subscribe(state => this.state = state);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshTokenRequest(request)) {
      if (this.state.submittingRefreshToken) {
        return;
      }
      this.incrementRefreshTokenRetry();
      this.setSubmittingTokenRefresh();
    }

    request = this.addAuthHeaders(request);
    return next.handle(request).pipe(
      tap((response) => this.recalculateState(request, response)),
      catchError((errorResponse) => {
        this.recalculateState(request, errorResponse);

        if (isServerError(errorResponse) || isNotInternetConnection(errorResponse)) {
          return this.handleInternalServerError(errorResponse);
        }

        if (isUnauthorized(errorResponse)) {
          return this.handleUnauthorizedError(errorResponse, request, next);
        }

        return throwError(() => errorResponse);
        }
      )
    );
  }

  private setSubmittingTokenRefresh() {
    this.authHttpInterceptorStore.save({
      ...this.state,
      submittingRefreshToken: true,
    });
  }

  private incrementRefreshTokenRetry() {
    this.authHttpInterceptorStore.save({
      ...this.state,
      refreshTokenRetries: this.state.refreshTokenRetries + 1
    });
  }

  private recalculateState(request: HttpRequest<any>, response: HttpResponseBase | any) {
    if (response instanceof HttpResponseBase && this.isRefreshTokenRequest(request)) {
      this.authHttpInterceptorStore.save({
        submittingRefreshToken: false,
        refreshTokenRetries: isSuccess(response) ? 0 : this.state.refreshTokenRetries,
        isValidAccessToken: isSuccess(response)
      });
    }
  }

  private handleUnauthorizedError(errorResponse, request: HttpRequest<any>, next: HttpHandler) {
    const isFirstUnauthorized = this.state.isValidAccessToken;
    this.invalidateStateAccessToken();

    if (this.isLoginRequest(request) || this.isRegisterRequest(request)) {
      return throwError(() => errorResponse);
    }

    if (!this.userSession.isLogged()) {
      this.handleLogout();
      return throwError(() => errorResponse);
    }

    if (this.isRefreshTokenRequest(request)) {
      if (this.state.refreshTokenRetries >= 3) {
        this.handleLogout();
        return throwError(() => errorResponse);
      }
      defer(() => this.authApi.refreshToken());
      return;
    }

    isFirstUnauthorized && defer(() => this.authApi.refreshToken());

    return from(this.waitToValidAccessToken()).pipe(
      switchMap(() => this.intercept(request, next))
    );
  }

  private invalidateStateAccessToken() {
    this.authHttpInterceptorStore.save({
      ...this.state,
      isValidAccessToken: false
    });
  }

  private async waitToValidAccessToken(): Promise<void> {
    await toPromise(this.authHttpInterceptorStore.state$, (state) => state.isValidAccessToken === true);
  }

  private async handleLogout(): Promise<void> {
    await this.authApi.logout();
    defer(() => this.router.navigate([this.routeResolver.resolve()]));
  }

  private isLoginRequest(request: HttpRequest<any>): boolean {
    return request.url.endsWith('/login') && !request.params.has('grant_type');
  }

  private isRegisterRequest(request: HttpRequest<any>): boolean {
    return request.url.endsWith('/register');
  }

  private isRefreshTokenRequest(request: HttpRequest<any>): boolean {
    return request.url.endsWith('/login') && request.params.get('grant_type') === AuthGrantType.refreshToken;
  }

  private handleInternalServerError(error: any) {
    defer(() => this.router.navigate([this.routeResolver.resolveErrorUrl()]));
    return throwError(() => error);
  }

  private addAuthHeaders(request: HttpRequest<any>) {
    if (this.me?.accessToken) {
      request = request.clone({
        setHeaders: {
          authorization: 'Bearer ' + this.me.accessToken
        }
      });
    }
    return request;
  }
}
